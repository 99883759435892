const { hash } = window.location;


class ModuleMapper {

  constructor(moduleMap = {}) {
    this.moduleMap = moduleMap;
    // this.modulePath = './modules/';
    this.globalLoadMethod = null;
  }

  camelCaseToSpinalCase(moduleName = '') {
    const noCamel = moduleName.replace(/([a-z](?=[A-Z]))/g, '$1 ');
    const newStr = noCamel.replace(/\s|_/g, '-');
    return newStr.toLowerCase();
  }

  catchHandler(moduleName = '', _err) {
    console.log(`inside of ${moduleName} catch handler`);
    console.log(_err);
    // debugger
    // do nothing with error
    return null;
  }

  loadModule(moduleName = '', selector = '') {
    const selectors = selector ? document.querySelectorAll(selector) : null;

    if (moduleName === '' || (selectors && selectors.length === 0)) {
      return;
    }

    // convert function name to spinal case for mapping to module file name; e.g. moduleName to module-name
    const reformattedModuleName = this.camelCaseToSpinalCase(moduleName);
    // const modulePath = `${this.modulePath}${reformattedModuleName}`;
    const moduleOptions = 'options' in this.moduleMap[moduleName] ? this.moduleMap[moduleName].options : null;

    // console.log(`loading module from `, `./modules/${reformattedModuleName}.ts`);

    return new Promise((resolve, reject) => {
      import(`./modules/${reformattedModuleName}.js`)
        .then(module => {
          if (moduleOptions) {
            module[moduleName]({
              ...{ containerClass: this.moduleMap[moduleName].selector },
              ...moduleOptions
            });
          } else {
            module[moduleName]();
          }
          resolve();
        })
        .catch(reject);
    });
  }

  loadScroll(selector, moduleName) {
    const els = document.querySelectorAll(selector);
    const preLoadIdentifier = 'dynamic-import-candidate';

    const options = {
      rootMargin: '600px',
      threshold: 0.01
    };

    // check if there are more than one instance of the module on the page currently
    if (!els || els.length === 0) {
      return;
    }

    // if the page url contains a hash, load all modules immediately
    if (hash !== '') {
      this.loadModule(moduleName);
      els.forEach(el => el.classList.add(preLoadIdentifier));
      return;
    }
      
    // console.log(`${els.length} instances of ${moduleName}`);
    let moduleHasBeenLoaded = false;
      
    // check if an instance of a module is in the viewport
    
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {

        if (entry.isIntersecting && !moduleHasBeenLoaded) {
          // console.log(`a ${moduleName} is intersecting, loading module...`);

          moduleHasBeenLoaded = true;
            
          this.loadModule(moduleName)
            .then(() => {
              // stop observing once dynamic import has occurred and set elements visibility back to visible
              els.forEach(el => {
                el.classList.add('dynamically-imported');
                observer.unobserve(el);
              });
              // console.log(`unobserving ${moduleName}`);
            })
            .catch(this.catchHandler);
        }
      });
    }, options);

    // observe each element on the page
    els.forEach(el => {
      el.classList.add(preLoadIdentifier);
      observer.observe(el);
    });
  }

  loadClick(selector, moduleName) {
    const els = document.querySelectorAll(selector);

    if (!els || els.length === 0) {
      return;
    }

    // the first click triggers the loading of the module, we need to initiate another click event so that the original desired action is then carried out after the module has been loaded
    const clickHandler = e => {
      const { target } = e;

      e.stopPropagation();
      e.preventDefault();

      this.loadModule(moduleName).then(() => {
        els.forEach(el => el.removeEventListener('click', clickHandler));

        // wait before retriggering click event
        setTimeout(() => {
          const event = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false
          });
    
          target.dispatchEvent(event);
        }, 9);
      });
    };

    els.forEach(el => {
      el.addEventListener('click', clickHandler);
    });
  }

  loadController(selector, moduleName, method) {
    if (method === 'scroll') {
      this.loadScroll(selector, moduleName);
    } else if (method === 'click') {
      this.loadClick(selector, moduleName);
    } else {
      this.loadModule(moduleName, selector);
    }
  }

  init() {
    if (!Object.keys(this.moduleMap).length) return;

    Object.keys(this.moduleMap).forEach(moduleName => {
      const config = this.moduleMap[moduleName];
      let { selector, method } = config;

      if (hash) {
        this.loadModule(moduleName, selector);
        return;
      }

      this.loadController(selector, moduleName, method);
    });
  }
}


export default ModuleMapper;
